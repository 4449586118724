<template>
  <div class="page">
    <navTop title="" :leftIcon="true"></navTop>
    <div class="home">
      <div class="propose-title">意见建议</div>
      <div class="propose-form">
        <div class="propose-form-item">
          <div class="propose-form-label">联系人:</div>
          <div class="propose-form-input">
            <van-field
              placeholder="请输入联系人名字"
              class="input-input"
              v-model="contact_name"
            />
          </div>
        </div>
        <div class="propose-form-item">
          <div class="propose-form-label">联系方式:</div>
          <div class="propose-form-input">
            <van-field
              placeholder="请输入联系方式"
              v-model="phone"
              class="input-input"
            />
          </div>
        </div>
        <div class="propose-form-item">
          <div class="propose-form-label">公司名称:</div>
          <div class="propose-form-input">
            <van-field
              placeholder="请输入公司名称"
              v-model="company_name"
              class="input-input"
            />
          </div>
        </div>
        <div class="propose-form-item">
          <div class="propose-form-label">意向建议内容:</div>
          <div class="propose-form-input1">
            <van-field
              placeholder="请输入意向建议内容"
              class="input-textarea"
              v-model="content"
              autosize
              type="textarea"
            />
          </div>
        </div>
      </div>
      <div class="propose-btn">
        <van-button color="#556efe" :disabled="disabled" @click="suggestAdd"
          >提交</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import navTop from "@/components/common/navTop.vue";
import { suggestAdd } from "@/api/index";
export default {
  components: {
    navTop
  },
  data() {
    return {
      contact_name: "",
      phone: "",
      content: "",
      company_name: "",
    };
  },
  computed: {
    disabled() {
      if (this.contact_name == "" || this.phone == "" || this.content == "") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    async suggestAdd() {
      let params = {
        contact_name: this.contact_name,
        phone: this.phone,
        content: this.content,
        company_name: this.company_name,
      };

      try {
        const { code, data } = await suggestAdd(params);
        this.$toast("提交成功");
        this.$router.go(-1);
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.propose-title {
  color: #000;
  font-size: 17px;
  margin-bottom: 10px;
  font-weight: 700;
}
.propose-form {
  margin-top: 25px;
}
.propose-form-label {
  color: #000;
  font-size: 14px;
  letter-spacing: 3px;
  margin-bottom: 5px;
  font-weight: 600;
}
.propose-form-item {
  margin-bottom: 10px;
}
.propose-form-input {
  border: 1px solid #ccc;
  height: 45px;
  overflow: hidden;
}
.input-input {
  height: 45px;
}
.propose-form-input1 {
  border: 1px solid #ccc;
}
.input-textarea {
  min-height: 200px !important;
}
.propose-btn {
  margin-top: 40px;
  text-align: center;
}
</style>
